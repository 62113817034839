// src/context/AuthContext.js
import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { requestNotificationPermission, onForegroundMessage } from '../utils/firebase-messaging';
import { Bounce, toast } from 'react-toastify';
import { editClient, getClientBy } from '../api/clientApi';

import { getNotificationsByClientId } from '../api/notificationApi';


const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [clientData, setClientData] = useState(null); // Optional: Store client details
  const [notificationToken, setNotificationToken] = useState(null);
  const [notifications, setNotifications] = useState(null);
  // Check if the user is logged in on initial load
  
  // Check if the user is logged in on initial load
  useEffect(() => {
    const storedClientData = JSON.parse(localStorage.getItem('client'));
    if (storedClientData) {
      setIsAuthenticated(true);
      setClientData(storedClientData);
    }
  }, []);

  // Fetch notifications when `clientData` is set
  useEffect(() => {
    if (clientData) {
      authenticate(clientData);
    }
  }, [clientData]);

  // Clear authentication state and remove from localStorage
  const logout = () => {
    setIsAuthenticated(false);
    setClientData(null);
    setNotifications([]);
    localStorage.removeItem('client');
  };

  const authenticate = (client) => {
    if (client) {
      setIsAuthenticated(true);
      initializeNotifications();
      localStorage.setItem('client', JSON.stringify(client));
      fetchNotifications(client.client_id); // Initial load of notifications
      return true;
    }
    return false;
  };

  const fetchNotifications = async (clientId) => {
    try {
      const notifData = await getNotificationsByClientId(clientId);
      setNotifications(notifData?.notifications || []);
      console.log('Client Notifications:', notifData.notifications);
    } catch (error) {
      console.error('Error fetching notifications:', error.message);
    }
  };
  const refreshClient = useCallback(async (clientId) => {
    try {
      const client = await getClientBy(clientId);
      localStorage.setItem('client', JSON.stringify(client));
      setClientData(()=>client)
    } catch (error) {
      console.error('Error fetching notifications:', error.message);
    }
  },[]);

  const initializeNotifications = async () => {
    try {
      // Request permission and get token
      const token = await requestNotificationPermission();
      setNotificationToken(token);

      // Set up foreground message handler
      const unsubscribe = onForegroundMessage(async (payload) => {
        // Show notification toast
        toast.info(`${payload.notification.body}`, {
          position: 'top-center',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          transition: Bounce,
        });

        // Update notifications state
        if (clientData?.client_id) {
          await fetchNotifications(clientData.client_id);
        }

        // Vibrate on notification
        if ('vibrate' in navigator) {
          navigator.vibrate(200);
        }
      });

      if (clientData?.client_id) {  // Save token to your backend
      await updateNotificationToken(token);
      }

      // Cleanup listener when component unmounts
      return () => {
        unsubscribe();
      };
    } catch (error) {
      console.error('Failed to initialize notifications:', error);
    }
  };

  const updateNotificationToken =async (token) => {
    try {
    const client=  await editClient({ client_id: clientData?.client_id, fcm_token: token });
    localStorage.setItem('client', JSON.stringify(client));
    } catch (error) {
      console.error('Error saving notification token:', error);
    }
  };


  return (
    <AuthContext.Provider value={{ isAuthenticated, clientData,notifications, authenticate, logout, setClientData,refreshClient }}>
      {children}
    </AuthContext.Provider>
  );
};
