import React from "react";
import { FaCar, FaEdit, FaPlusCircle } from "react-icons/fa";
import { IoMdCloseCircle } from "react-icons/io";
import { MdDeleteForever } from "react-icons/md";

const ManageVehicle = ({
  isOpen,
  onClose,
  onEdit,
  onRemove,
  vehicleData,
  removeSub,
  addToSub,
  selectedSubscription = null,
  isBelongtoSub = false,
}) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="flex flex-col items-center bg-white rounded-lg shadow-lg p-6 w-[90%]">
        <div className="w-full relative">
          <h2 className="w-full text-black  text-center text-xl font-bold mb-4">
            Manage Vehicle
          </h2>
          <button
            className="absolute text-black text-3xl  rounded-full hover:text-orange-700 right-2 top-0"
            onClick={onClose}
          >
            <IoMdCloseCircle />
          </button>
        </div>
        <div className="h-[2px] w-full  bg-gray-700"></div>

        <div className="col-span-2 md:col-span-1 flex  items-center justify-center gap-2 my-4">
          <small className="text-gray-500">Plate Number</small>{" "}
          <div className="font-bold bg-gray-200 px-4 py-2 rounded-lg text-black">
            {vehicleData?.plate_number}
          </div>
        </div>

        <div className="flex flex-wrap items-center justify-center gap-2 my-4">
          <button
            className="flex justify-center  items-center gap-1 p-2 w-full rounded-full bg-orange-400 text-black    hover:bg-orange-400"
            onClick={()=>onRemove(vehicleData)}
          >
            <MdDeleteForever /> Delete Car
          </button>

          <button
            className="flex justify-center  items-center  space-x-2 gap-1 p-2 w-full rounded-full bg-blue-300 text-black   hover:bg-blue-400"
            onClick={()=>onEdit(vehicleData)}
          >
            <FaEdit /> Edit Car
          </button>
        </div>
        {selectedSubscription &&
          selectedSubscription !== undefined &&
          Object.keys(selectedSubscription).length > 0 &&
          (isBelongtoSub ? (
            <div className="flex flex-col justify-ceneter items-center flex-wrap w-full rounded-xl  space-x-2 gap-1 text-black ring-1 ring-black  py-3 px-3 font-bold">
              <small className=" flex items-center">
                Current Subscription: {selectedSubscription.subscription_name}
              </small>
              <button
                className="text-black text-3xl px-4 py-2 hover:bg-orange-400"
                onClick={()=>removeSub(vehicleData)}
              >
                <MdDeleteForever />
              </button>
            </div>
          ) : (
            <div className="flex flex-col justify-ceneter items-center flex-wrap w-full rounded-xl space-x-2 gap-1 text-black ring-1 ring-black  py-3 px-3 font-bold">
              <small className=" flex items-center">
                {selectedSubscription.subscription_name} -{" "}
                {selectedSubscription.subscribedVehicles?.length} /{" "}
                {
                  JSON.parse(selectedSubscription.subscription_features)
                    ?.number_of_cars
                }
                <FaCar className="mx-2" />
              </small>
              <button
                className="text-black text-3xl px-4 py-2 hover:bg-orange-400  disabled:text-slate-300"
                onClick={()=>addToSub(vehicleData)}
                disabled={selectedSubscription.subscribedVehicles?.length >=JSON.parse(selectedSubscription.subscription_features)
                    ?.number_of_cars}
              >
                <FaPlusCircle />
              </button>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ManageVehicle;
