// src/api/vehicleApi.js
import config from '../config';

export const fetchVehicles = async (filters = {}) => {

 const query = new URLSearchParams(filters).toString();
  const response = await fetch(`${config.apiUrl}/vehicles/search/param=?&${query}`);

  if (!response.ok) throw new Error('Failed to fetch vehicles');
  return response.json();
};

export const createVehicle = async (vehicleData) => {
  const response = await fetch(`${config.apiUrl}/vehicles/`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(vehicleData),
  });
  if (!response.ok) {
    const errorResponse = await response.json(); // Parse the error response
    throw new Error(JSON.stringify(errorResponse)); // Throw the real error message
  }
  return response.json();
};

export const deleteVehicle = async (vehicleData) => {
  const response = await fetch(`${config.apiUrl}/vehicles/${vehicleData.vehicle_id}`, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(vehicleData),
  });

  if (!response.ok) {
    const errorResponse = await response.json(); // Parse the error response
    throw new Error(JSON.stringify(errorResponse)); // Throw the real error message
  }
  return response.json();
};

export const editVehicle = async (vehicleData) => {
  const response = await fetch(`${config.apiUrl}/vehicles/${vehicleData.vehicle_id}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(vehicleData),
  });

  if (!response.ok) {
    const errorResponse = await response.json(); // Parse the error response
    throw new Error(JSON.stringify(errorResponse)); // Throw the real error message
  }
  return response.json();
};