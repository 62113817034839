import CarBrandLogo from "./CarBrandLogo";

const MyCarItem=({vehicleData, isBelongtoSub, setVehicle})=>{
    const handleClick = () => {
        setVehicle(vehicleData); // Calls the `handleSetVeh` function passed as a prop.
      };
return (
    <div  
    type="div"
    onClick={handleClick}
    className={`flex items-center justify-between  flex-wrap py-2 px-6  gap-3 max-[450px]:p-1 text-black font-bold rounded-lg bg-slate-200  shadow-lg w-4/5 ${isBelongtoSub?"ring-4 ring-orange-400":''}`}
  >
    <CarBrandLogo model={vehicleData?.model} />
    <div className="col-span-2 md:col-span-1 flex flex-col items-center justify-center">
      <div className="text-xl font-bold bg-gray-200 px-4 py-2 rounded-lg">
        {vehicleData?.plate_number}
      </div>
      <small className="text-gray-500 mt-1">Plate Number</small>
    </div>
    <div className="flex flex-col items-center justify-center">
      <div
        className="w-12 h-12 rounded-full border-4 border-gray-300"
        style={{ backgroundColor: vehicleData?.color }}
      />
      <small className="text-gray-500 mt-1">Color</small>
    </div>
    {/* Car Type */}
    <div className="flex flex-col items-center justify-center">
      <div className="bg-blue-100 text-blue-800 px-3 py-1 rounded-full">
        {vehicleData?.registration}
      </div>
      <small className="text-gray-500 mt-1">Vehicle Type</small>
    </div>
  </div>
)
}
export default MyCarItem;