import React from "react";
import config from '../../config';
import { FaCar, FaTrash } from "react-icons/fa";
import { MdLocalCarWash } from "react-icons/md";
const SubscriptionCardView = ({ subscription, onDelete,showDelete,styles={} }) => {
  const {
    name_en,
    icon,
    color,
    cost,
    duration_days,
    subscriptionServices,
    number_of_cars,
    number_of_washes
  } = subscription;

  return (
    <div
      className={`relative w-60 h-80  max-[450px]:w-56 max-[450px]:h-72  rounded-3xl shadow-lg overflow-hidden mt-2 max-[450px]:mt-1 ${styles}`}
      style={{ backgroundColor: color || "#f0f0f0" }} >    
    { showDelete && <div className="absolute top-4 right-4">
        <button className=" text-white" onClick={(e) => {
          e.stopPropagation(); // Prevent event bubbling
          onDelete(subscription)
        }}>
          <FaTrash />
        </button>
      </div>}
      {/* Name */}   
      <div className="absolute top-4 max-[450px]:top-2 sm:top-2  text-left w-full px-4 max-[450px]:px-2 sm:px-2 " >
      <div className="flex items-end  justify-between">  {/* Duration */}
      <h2 className="text-white text-3xl max-[450px]:text-2xl sm:text-2xl font-bold tracking-wider w-[75%] max-w-[75%] overflow-hidden  whitespace-break-spaces">
        {name_en?.toUpperCase()} 
      </h2>
      <div className="text-white text-sm px-4 max-[450px]:px-2 sm:px-2">
        <span>{duration_days} days</span>
      </div></div>
      <div className="mt-2 h-1 w-full bg-white/30 rounded"></div>
       {/* Services Highlights */}
       <div className=" mt-2 flex items-start  justify-between">
       <div className="text-white text-sm px-4 right-4 max-[450px]:px-2 max-[450px]:right-2 sm:px-2 sm:right-2 font-extrabold flex items-center gap-2 ">
        {number_of_washes || 'unlimited' } <MdLocalCarWash />
      </div>
        
        <div className="text-white text-sm px-4 right-4 max-[450px]:px-2 max-[450px]:right-2 sm:px-2 sm:right-2 font-extrabold flex items-center gap-2 ">
        {number_of_cars } <FaCar /> 
      </div>
      
      </div>    
      <ul className="text-sm text-white space-y-1 mt-2 tracking-wider w-[80%]">
          <h2 className="font-bold">Services</h2>
          {subscriptionServices?.slice(0, 3).map((service, index) => (
            <li key={index} className="truncate">
              • {service.serviceType.name_en}
            </li>          
          ))}
          {subscriptionServices?.length > 3 && <li>• +{subscriptionServices.length - 3} more</li>}
        </ul>
    </div>

     
  {/* Icon */}
  <div className="absolute bottom-4 left-4 max-[450px]:bottom-2 max-[450px]:left-2 sm:bottom-2 sm:left-2">
        {icon ? (
          <img src={config.apiUrl + icon} alt="Subscription Icon" className="w-12 max-[450px]:w-10 sm:10" />
        ) : (
          <div className="w-12 h-12  max-[450px]:w-10 sm:10 bg-gray-200 rounded-full flex items-center justify-center">
            <span className="text-sm text-gray-500">Icon</span>
          </div>
        )}
      </div>
      {/* Cost */}
      <div className="absolute bg-gradient-to-r from-[#F9EED9] to-[#DEB686]  rounded-full p-2 bottom-4 right-4 max-[450px]:bottom-2 max-[450px]:right-2 sm:bottom-2 sm:right-2 text-stone-800 text-lg font-extrabold">
        <span> {parseFloat(cost).toFixed(0)} QAR</span>
      </div>
    </div>
  );

};

export default SubscriptionCardView;
