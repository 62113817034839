import React, { useState } from "react";
import InstallPWAButton from "../components/InstallPWAButton";
import SubscriptionList from "../components/Cards/SubscriptionList";
import BannerImage from "../components/BannerImage";
import BranchList from "../components/BranchList";
import FreeWash from "../components/FreeWash";
import SubscriptionDetails from "../components/SubscriptionDetails";

const Home = () => {
  const [isShowSubDetails, setisShowSubDetails] = useState(false);
  const [subscription, setSubsciption] = useState([]);
  const handleSetSub =(subscription)=>{setSubsciption(subscription); setisShowSubDetails(true)};
  return (
    <div className="flex flex-col items-center justify-start min-h-screen mb-20 ">
      <BannerImage />
      <InstallPWAButton />
      <SubscriptionList ChooseSubsciption={handleSetSub}  />
    <FreeWash />
      <BranchList />
      <SubscriptionDetails isShowSubDetails={isShowSubDetails} subscription={subscription} onHide={()=>setisShowSubDetails(false)} />
    </div>
  );
};

export default Home;
