import React from 'react';

const SubscribeConfirmation = ({ isOpen, onClose, onConfirm, payload }) => {
  if (!isOpen) return null;
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="flex flex-col items-center bg-white rounded-lg shadow-lg p-6 w-4/5">
        <h2 className="w-full  text-center text-xl font-bold mb-4">{payload.subscription_name} - {payload.subscription_cost} QAR</h2>
        <div className="h-[2px] w-full  bg-gray-700" ></div>
        <p className="mb-4">Do you want to proceed?</p>

        <div className="flex justify-center flex-wrap space-x-2 gap-1">
          <button
            className="bg-orange-500 text-white px-4 py-2 rounded-full hover:bg-orange-700"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="bg-blue-400 text-white px-4 py-2 rounded-full hover:bg-blue-700"
            onClick={onConfirm}
          >
           Cash at GOCLEANS Branch
          </button>
        </div>
      </div>
    </div>
  );
};

export default SubscribeConfirmation;
