import React from "react";

const Profile = () => {
  return (
    <div className="flex items-center justify-center h-full">
      <h1 className="text-2xl font-bold">Profile</h1>

      
    </div>
  );
};

export default Profile;
