import { useEffect, useState } from "react";
import { fetchSubscriptions } from "../../api/subscriptionApi";
import SubscriptionCardView from "./SubscriptionCardView";
import SubscriptionDetails from "../SubscriptionDetails";


const SubscriptionList = ({ ChooseSubsciption }) => {
  const [subscriptions, setSubscriptiones] = useState([]);
  
  useEffect(() => {
    async function getsubscriptions() {
      const data = await fetchSubscriptions({}); // Fetch subscription list
      setSubscriptiones(data);
 
    }
    getsubscriptions();
  }, []);
  const handlehooseSubsciption = (data) => {
    ChooseSubsciption(data)
  };


  return subscriptions.length ? (<>
    <div className="w-full mt-2 max-[450px]:mt-1 sm:mt-2 flex  items-center  gap-3">

      <svg className="w-10 sm:w-12 " viewBox="0 0 54 48" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M24 0H30C31.6594 0 33 1.34062 33 3V9C33 10.6594 31.6594 12 30 12H24C22.3406 12 21 10.6594 21 9V3C21 1.34062 22.3406 0 24 0ZM6 6H18V10.5C18 12.9844 20.0156 15 22.5 15H31.5C33.9844 15 36 12.9844 36 10.5V6H48C51.3094 6 54 8.69063 54 12V42C54 45.3094 51.3094 48 48 48H6C2.69063 48 0 45.3094 0 42V12C0 8.69063 2.69063 6 6 6ZM16.5 40.9969C16.5 41.55 16.95 42 17.5031 42H36.5062C37.0594 42 37.5094 41.55 37.5094 40.9969C37.5094 38.2312 35.2687 36 32.5125 36H21.5156C18.75 36 16.5187 38.2406 16.5187 40.9969H16.5ZM27 33C28.5913 33 30.1174 32.3679 31.2426 31.2426C32.3679 30.1174 33 28.5913 33 27C33 25.4087 32.3679 23.8826 31.2426 22.7574C30.1174 21.6321 28.5913 21 27 21C25.4087 21 23.8826 21.6321 22.7574 22.7574C21.6321 23.8826 21 25.4087 21 27C21 28.5913 21.6321 30.1174 22.7574 31.2426C23.8826 32.3679 25.4087 33 27 33Z" fill="#FF8C00" />
      </svg>
     <div>
     <h1 className="text-orange-500  font-bold">Subcribe Now !</h1>
     <h3 className="text-gray-500 text-sm  font-bold" >Unlock exclusive car wash packages tailored to keep your vehicle shining all year round. Choose GOCleans for unbeatable quality and convenience!</h3></div> 
    </div>
    <div className="w-full max-w-full overflow-x-auto flex flex-nowrap gap-2  p-2 rounded-md justify-around items-center my-1">

      {subscriptions.map((subscriptionData) => (
        <button
          key={subscriptionData.subscription_id}
          type="button"
          onClick={() => handlehooseSubsciption(subscriptionData)}
          style={{ backgroundColor: subscriptionData.color || "#f0f0f0" }}
          className="p-2 max-[450px]:p-1 text-white rounded-3xl items-start shadow-lg"
        >
          <SubscriptionCardView
            subscription={subscriptionData}
            onDelete={() => { }}
            showDelete={false}
          />
        </button>
      ))}
    </div>
    
  </>
  ) : (
    <p>Loading subscriptions...</p>
  );

}

export default SubscriptionList;