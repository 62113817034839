import { useEffect, useState } from "react";
import carData from "./../../carData.json";
const CarBrandLogo=({model}) =>{

    const [brandLogo, setBrandLogo] = useState("");
  useEffect(() => {
    const filteredCar = carData.find((car) =>
      car.name.toLowerCase() === model?.toLowerCase()
    );
    if (filteredCar) {
      setBrandLogo(filteredCar.image.localThumb); // Assign the logo URL
    } else {
      setBrandLogo(""); // Fallback if no match
    }
  }, [model]);

  return (  <div className="flex flex-col items-center justify-center">
    {brandLogo ? (
      <img
        src={brandLogo}
        alt={model}
        className="w-12 h-12 object-contain"
      />
    ) : (
      <div className="w-12 h-12 bg-gray-300 rounded-lg flex items-center justify-center">
        <span className="text-gray-500 text-sm">No Logo</span>
      </div>
    )}
    <small className="text-black mt-1 font-bold">{model}</small>
  </div>);
}

export default CarBrandLogo;