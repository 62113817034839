import React from 'react';
import { FaPlusCircle } from 'react-icons/fa';

const EmptyAdd = ({ 
  onAddClick, 
  placeholderText = "Search...", 
  addButtonIcon = <FaPlusCircle /> ,
  color='text-blue-300'
}) => {
  return (
    <div className="flex flex-col justify-center items-center m-2 space-x-2">
      <div className="text-gray-700">
      {placeholderText}
      </div>
    
      <button
        onClick={onAddClick}
        className={`${color} text-4xl px-4 py-2 rounded-md hover:text-blue-500 transition-colors`}
      >
        {addButtonIcon}
      </button>
    </div>
  );
};

export default EmptyAdd;