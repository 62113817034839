import React from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './App.css';
import Layout from './Layout';
import Home from './pages/Home';
import Go from './pages/Go';
import Profile from './pages/Profile';
import LoginPage from './pages/LoginPage';
import Register from './pages/Register';
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import CompleteProfile from './pages/CompleteProfile';
import ChangeMobile from './pages/ChangeMobile';

function App() {
  return (
 <>  <Router>
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Home />} />
        <Route path="go" element={<Go />} />
        <Route path="profile" element={<Profile />} />
      </Route>
      <Route path="login" element={<LoginPage />} />
      <Route path="register" element={<Register />} />
      <Route path="update" element={<CompleteProfile />} />
      <Route path="update-mobile" element={<ChangeMobile />} />
    </Routes>
  </Router>
      <ToastContainer /></> 
  );
}

export default App;
