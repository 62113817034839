import { useAuth } from "../context/AuthContext";


const FreeWash=()=>{

   const {isAuthenticated, clientData} =useAuth();
   if ( isAuthenticated && !clientData?.free_wash_status){return;}

    return (
        <div className="flex items-center gap-3 animate-pulse p-4 rounded-full shadow-xl bg-[#66B8D9]">
        <svg
          className="w-8"
          viewBox="0 0 87 87"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fill="#FCFBFA"
            d="M39.5896 76.1436L36.4191 65.5644C33.6712 56.4664 26.5903 49.3783 17.5013 46.6277L6.93278 43.454C3.02241 42.2903 3.02241 36.7891 6.93278 35.6254L17.5013 32.4516C26.5903 29.701 33.6712 22.613 36.4191 13.5149L39.5896 2.93572C40.7522 -0.978574 46.2478 -0.978574 47.4104 2.93572L50.5809 13.5149C53.3288 22.613 60.4097 29.701 69.4987 32.4516L80.0672 35.6254C83.9776 36.7891 83.9776 42.2903 80.0672 43.454L69.4987 46.6277C60.4097 49.3783 53.3288 56.4664 50.5809 65.5644L47.4104 76.1436C46.2478 79.9521 40.7522 79.9521 39.5896 76.1436Z"
          />
        </svg>
        <div className="font-bold"> GET YOUR FREE WASH <br></br><span className="font-light">Register and wash for free</span></div>
      </div>
    );}

export default FreeWash;