// src/api/branchApi.js
import config from "../config";

export const fetchBranches = async (filters = {}) => {
  const query = new URLSearchParams(filters).toString();
  const response = await fetch(
    `${config.apiUrl}/branchs/search/param=?&${query}`
  );

  if (!response.ok) {
    const errorResponse = await response.json(); // Parse the error response
    throw new Error(JSON.stringify(errorResponse)); // Throw the real error message
  }
  return response.json();
};

export const createBranch = async (branchData) => {
  const response = await fetch(`${config.apiUrl}/branchs/`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(branchData),
  });
  if (!response.ok) {
    const errorResponse = await response.json(); // Parse the error response
    throw new Error(JSON.stringify(errorResponse)); // Throw the real error message
  }
  return response.json();
};

export const deleteBranch = async (branchData) => {
  const response = await fetch(
    `${config.apiUrl}/branchs/${branchData.branch_id}`,
    {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(branchData),
    }
  );
  if (!response.ok) {
    const errorResponse = await response.json(); // Parse the error response
    throw new Error(JSON.stringify(errorResponse)); // Throw the real error message
  }
  return response.json();
};

export const editBranch = async (branchData) => {
  const response = await fetch(
    `${config.apiUrl}/branchs/${branchData.branch_id}`,
    {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(branchData),
    }
  );
  if (!response.ok) {
    const errorResponse = await response.json(); // Parse the error response
    throw new Error(JSON.stringify(errorResponse)); // Throw the real error message
  }
  return response.json();
};

export const formatTime = (isoString) => {
  const date = new Date(isoString);
  const hours = String(date.getUTCHours()).padStart(2, "0");
  const minutes = String(date.getUTCMinutes()).padStart(2, "0");
  return `${hours}:${minutes}`;
};
