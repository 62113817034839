import { updateClientProfilePhoto } from "../api/clientApi";
import config from "../config";
import { useAuth } from "../context/AuthContext";

const ClientProfilePhoto = () => {

    const { setClientData, clientData } = useAuth();


    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        const client_id = clientData?.client_id;

        if (file) {
            const formData = new FormData();
            formData.append('profile_pic', file);
            try {
                // Attempt to update the profile photo
                const updateClient = await updateClientProfilePhoto(formData, client_id);          
                setClientData(updateClient);
              } catch (error) {
                console.error("Failed to upload profile photo:", error);
                alert("Profile photo upload failed. Please try again.");
              }
        }
    };
    return (
        <div className="relative">
            {/* Make the profile picture clickable */}
            {clientData?.profile_pic ? (
                <img
                    className="w-8 h-8 rounded-full shadow-lg ring-2 ring-gray-300 dark:ring-gray-500 cursor-pointer"
                    src={`${config.apiUrl}/assets/uploads/${clientData.profile_pic}`}
                    alt={`${clientData.username} profile`}
                    onClick={() => document.getElementById('fileInput').click()}
                />
            ) : (
                <svg
                    className="w-8 h-8 rounded-full ring-2 ring-gray-300 dark:ring-gray-500 cursor-pointer"
                    viewBox="0 0 140 160"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => document.getElementById('fileInput').click()} // Trigger file input on click
                >
                    <path
                        d="M70 80C80.6087 80 90.7828 75.7857 98.2843 68.2843C105.786 60.7828 110 50.6087 110 40C110 29.3913 105.786 19.2172 98.2843 11.7157C90.7828 4.21427 80.6087 0 70 0C59.3913 0 49.2172 4.21427 41.7157 11.7157C34.2143 19.2172 30 29.3913 30 40C30 50.6087 34.2143 60.7828 41.7157 68.2843C49.2172 75.7857 59.3913 80 70 80ZM55.7188 95C24.9375 95 0 119.938 0 150.719C0 155.844 4.15625 160 9.28125 160H130.719C135.844 160 140 155.844 140 150.719C140 119.938 115.062 95 84.2812 95H55.7188Z"
                        fill="#66B8D9"
                    />
                </svg>
            )}
            <input
                id="fileInput"
                type="file"
                className="hidden"
                accept="image/*"
                onChange={handleFileChange} // Handle file change
            />
        </div>
    );
}
export default ClientProfilePhoto