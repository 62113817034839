import { BiSolidOffer } from "react-icons/bi";
import { FaBell, FaCreditCard, FaStar } from "react-icons/fa";
import { MdLocalCarWash, MdLocalOffer } from "react-icons/md";

const NotificationsList=({isSidebarOpen,notifications,toggleSidebar})=>{
    const typeIcons = {
        wash: <MdLocalCarWash className="text-blue-500 text-xl" />,
        payment: <FaCreditCard className="text-green-500 text-xl" />,
        ads: <FaStar className="text-yellow-500 text-xl" />,
        coupon: <BiSolidOffer className="text-yellow-500 text-xl" />,
        subscribe: <MdLocalOffer className="text-red-500 text-xl" />,
      };
    return (
        <div
        className={`fixed top-0 right-0 h-full bg-white shadow-lg transition-transform transform  duration-500 ${
          isSidebarOpen ? "translate-x-0" : "translate-x-full"
        } w-[85%] max-w-[400px]`}
      >
        {/* Header */}
        <div className="flex justify-between items-center p-4 border-b">
          <h2 className="text-lg font-bold">Notifications</h2>
          <button
            onClick={toggleSidebar}
            className="text-gray-600 hover:text-gray-800"
          >
            Close
          </button>
        </div>

        {/* Notifications List */}
        <div className="overflow-y-auto h-[calc(100%-56px)] p-4 space-y-4">
          {notifications?.length > 0 ? (
            notifications.map((notification) => (
              <div
                key={notification.notification_id}
                className={`flex items-start p-3 rounded-lg shadow-sm ${
                  notification.read_status
                    ? "bg-gray-100"
                    : "bg-yellow-50 border-l-4 border-yellow-400"
                }`}
              >
                {/* Icon */}
                <div className="mr-4">
                  {typeIcons[notification.type] || (
                    <FaBell className="text-gray-500 text-xl" />
                  )}
                </div>
                {/* Content */}
                <div className="flex-1">
                  <h3 className="font-semibold">{notification.title}</h3>
                  <p className="text-sm text-gray-600">{notification.body}</p>
                  <p className="text-xs text-gray-400 mt-1">
                    {new Date(notification.createdAt).toLocaleString()}
                  </p>
                </div>
              </div>
            ))
          ) : (
            <p className="text-gray-600 text-center">No notifications found.</p>
          )}
        </div>
      </div>
    );

}
export default NotificationsList;