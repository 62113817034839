import { FaBell, FaBullhorn, FaDollarSign, FaWater } from "react-icons/fa";
import { useAuth } from "../context/AuthContext";
import { useEffect, useState } from "react";
import NotificationsList from "./NotificationsList";

const NotifcationBell =()=>{
 const {notifications}  = useAuth();

   // Check if there are any unread notifications

   const [isSidebarOpen, setSidebarOpen] = useState(false);
   const [hasUnreadNotifications, setHasUnreadNotifications] = useState(false);

   const toggleSidebar = () => {setSidebarOpen(!isSidebarOpen); setHasUnreadNotifications(false)}

   useEffect(()=>{   const hasUnread= notifications?.some((notif) => !notif.read_status);
    setHasUnreadNotifications(hasUnread);
   },[notifications]);
 return(
    <>
    <div className="relative">
    <button  onClick={toggleSidebar} className="p-2 rounded-full text-xl">
      <FaBell />
    </button>
    {/* Notification dot */}
    {hasUnreadNotifications && (
    <span className="absolute top-0 right-0 w-2 h-2 bg-red-600 rounded-full"></span>)}
  </div>
  <NotificationsList isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} notifications={notifications} />
  </>
 );
}
export default NotifcationBell;