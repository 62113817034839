import React, { useState } from "react";

import carData from "./../carData.json";

const CarList= ({  onCarSelect }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const filteredCars = carData.filter((car) =>
    car.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="w-full">
            <div className="relative flex items-center ">
          <input
            type="text"
            placeholder="Search cars models..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="bg-gray-50 border border-gray-300 text-gray-900 text-sm font-extrabold text-black-500 rounded-full 
      focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 
      dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 
      dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
          />

            <div className="absolute inset-y-0 end-4 flex items-center pe-3">
              <svg
                className="w-4 h-4 text-gray-500 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 20"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                />
              </svg>
            </div>
        </div>
    
      <div className="grid grid-cols-4 gap-4 p-4  h-60 overflow-y-scroll ">
        {filteredCars.map((car) => (
          <div
            key={car.name}
            className="items-center justify-center flex flex-col gap-1 hover:bg-blue-400 cursor-pointer"
            onClick={() => onCarSelect(car.name)}
          >
            <img src={`${car.image.localThumb}`} alt={car.name} className="w-14" />
            <p>{car.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CarList;
