import React, { useState } from "react";
import { searchCoupon } from "../../api/couponApi";
const CouponModal = ({ isOpen, onClose, onConfirm }) => {
    const [couponCode, setCouponCode] = useState("");
    const [couponDetails, setCouponDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");

    const handleFetchCoupon = async () => {
        setLoading(true);
        setError("");
        try {
            const response = await searchCoupon(`name=${couponCode}`);
            if (response) {
                setCouponDetails(response);
            } else {
                setError("Not Found");
                setCouponDetails(null);
            }
        } catch (err) {
            setError("Failed to fetch coupon details. Please try again.");
            setCouponDetails(null);
        } finally {
            setLoading(false);
        }
    };

    const handleConfirm = () => {
        if (couponDetails) {
            onConfirm(couponDetails);
            onClose();
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-blue-400 rounded-lg shadow-lg w-full max-w-md p-6">
                <h2 className="text-xl text-white font-bold mb-4 text-center">
                    Enter Coupon Code!
                </h2>
                <div className="relative w-full">
                    <input
                        type="text"
                        placeholder="Enter your coupon code"
                        value={couponCode}
                        onChange={(e) => setCouponCode(e.target.value)}
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-full 
            focus:ring-blue-500 focus:border-blue-500 block w-full ps-10 p-2.5 
            dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 
            dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    />
                    <div className="absolute inset-y-0 end-0 flex items-center pe-3">
                        <button
                            onClick={handleFetchCoupon}
                            disabled={!couponCode || loading}
                            className=" text-black px-4 py-2 rounded hover:bg-blue-600 disabled:bg-gray-400"
                        >
                            {loading ? (
                                "Fetching..."
                            ) : (
                                <svg
                                    className="w-4 h-4 text-gray-500 dark:text-gray-400 hover:text-gray-900 dark:hover:text-white"
                                    aria-hidden="true"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 20 20"
                                >
                                    <path
                                        stroke="currentColor"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                                    />
                                </svg>
                            )}
                        </button>
                    </div>
                </div>
                {error && <p className="text-orange-600 mt-2">{error}</p>}
                {couponDetails && (
                    <div className="mt-4 border-t pt-4 text-center">
                        <p>
                            <strong>{couponDetails.name}</strong>
                        </p>


                        {new Date(couponDetails.valid_until) < new Date() ? (
                            <p className="expired-message">This coupon is expired.</p>
                        ) : (<p> <strong>Valid Until:</strong>{" "}
                            {new Date(couponDetails.valid_until).toLocaleDateString()}</p>)}

                        <p className=" font-extrabold text-2xl ">
                            {couponDetails.discount_type === "percentage"
                                ? `${couponDetails.discount_value}%`
                                : `QAR ${couponDetails.discount_value}`}
                        </p>
                    </div>
                )}
                <div className="mt-6 flex justify-end gap-2">
                    <button
                        onClick={onClose}
                        className="bg-slate-800 text-white px-4 py-2 rounded-full hover:bg-gray-800"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={handleConfirm}
                        disabled={!couponDetails || new Date(couponDetails.valid_until) < new Date()}
                        className="bg-green-600 text-white px-4 py-2 rounded-full hover:bg-green-600 disabled:bg-gray-400"
                    >
                        APPLY
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CouponModal;
