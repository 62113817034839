import React, { useCallback } from "react";
import config from "../../config";
import { FaCalendarAlt, FaCar, FaTrash } from "react-icons/fa";
import { MdLocalCarWash } from "react-icons/md";
const MySubscriptionCard = ({
  subscription,
  onDelete,
  showDelete,
  styles = {},
}) => {
  const {
    client_subscription_id,
    subscription_name,
    subscription_features,
    subscription_cost,
    created_date,
    end_date,
    payment_status,
    subscribedVehicles,
  } = subscription;
  const checkSubscriptionStatus = useCallback((endDate, created_date) => {
    const today = new Date();
    const start = new Date(created_date);
    const end = new Date(endDate);
    const timeDifference = end - today;
    const total = end - start;
    const daysRemaining = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    const isExpired = daysRemaining < 0;
    const duration = Math.ceil(total / (1000 * 60 * 60 * 24));
    return {
      isExpired,
      daysRemaining: isExpired ? 0 : daysRemaining,
      duration: duration,
    };
  }, []);
  if (!subscription) return;
  return (
    <div
      className={`relative w-60 h-72  max-[450px]:w-56 max-[450px]:h-72  rounded-3xl overflow-hidden mt-2 max-[450px]:mt-1 ${styles}`}
      style={{
        backgroundColor: JSON.parse(subscription_features).color || "#f0f0f0",
      }}
    >
      {showDelete && (
        <div className="absolute top-4 right-4">
          <button
            className=" text-white"
            onClick={(e) => {
              e.stopPropagation(); // Prevent event bubbling
              onDelete(subscription);
            }}
          >
            <FaTrash />
          </button>
        </div>
      )}
      {/* Name */}
      <div className="absolute top-4 max-[450px]:top-2 sm:top-2  text-left w-full px-4 max-[450px]:px-2 sm:px-2 ">
        <div className="flex items-end  justify-between ">
      
          <h2 className="text-white text-3xl max-[450px]:text-2xl sm:text-2xl font-bold tracking-wider w-[50%] max-w-[50%] overflow-hidden text-ellipsis whitespace-nowrap">
            {subscription_name?.toUpperCase()}
          </h2>
          <div className="text-white text-sm px-4 max-[450px]:px-2 sm:px-2">
            <span>
              {checkSubscriptionStatus(end_date, created_date).isExpired? "Expired":checkSubscriptionStatus(end_date, created_date).daysRemaining} /{" "}
              {checkSubscriptionStatus(end_date, created_date).duration}
            </span>
            <FaCalendarAlt />
          </div>
        </div>
        <div className="mt-2 h-1 w-full bg-white/30 rounded"></div>
        {/* Services Highlights */}
        <div className=" mt-2 flex items-start  justify-between">
          <div className="text-white text-sm px-4 right-4 max-[450px]:px-2 max-[450px]:right-2 sm:px-2 sm:right-2 font-extrabold flex items-center gap-2 ">
            {JSON.parse(subscription_features)?.number_of_washes || "unlimited"}{" "}
            <MdLocalCarWash />
          </div>

          <div className="text-white text-sm px-4 right-4 max-[450px]:px-2 max-[450px]:right-2 sm:px-2 sm:right-2 font-extrabold flex items-center gap-2 ">
            {subscribedVehicles?.length} /{" "}
            {JSON.parse(subscription_features)?.number_of_cars} <FaCar />
          </div>
        </div>
        <ul className="text-sm text-white space-y-1 mt-2 tracking-wider w-[80%]">
          <h2 className="font-bold">Services</h2>
          {JSON.parse(subscription_features)
            ?.subscriptionServices?.slice(0, 3)
            .map((service, index) => (
              <li key={index} className="truncate">
                • {service.serviceType.name_en}
              </li>
            ))}
          {JSON.parse(subscription_features)?.subscriptionServices?.length >
            3 && (
            <li>
              • +
              {JSON.parse(subscription_features)?.subscriptionServices.length -
                3}
              more
            </li>
          )}
        </ul>
      </div>
      <div className="absolute w-full p-2 bottom-1   font-extrabold">
        <span>
          {payment_status} - {parseFloat(subscription_cost).toFixed(0)} QAR
        </span>
         <button onClick={()=>onDelete(client_subscription_id)} className=" text-xs  py-1 w-full bg-slate-800 text-white rounded-full">Cancel</button>
      </div>
    </div>
  );
};

export default MySubscriptionCard;
