
// Import the functions you need from the SDKs you need
import React, { useEffect, useState } from 'react';
import app,{ auth} from '../config/firebase';
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber, } from 'firebase/auth';
import { Bounce, toast } from 'react-toastify';
import { Loader2 } from 'lucide-react';



const MobileValidation = ({ onValidation, mobile=''}) => {
    const [phone, setPhone] = useState(mobile);
  const [otp, setOtp] = useState('');
  const [showOTP, setShowOTP] = useState(false);
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);
  const [verificationSuccess, setVerificationSuccess] = useState(false);

  useEffect(() => {
    setupRecaptcha();
    return () => {
      // Cleanup reCAPTCHA on unmount
      if (window.recaptchaVerifier) {
        window.recaptchaVerifier.clear();
      }
    };
  }, []);

  useEffect(() => {
    let timer;
    if (timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft(time => time - 1);
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [timeLeft]);

  const setupRecaptcha = () => {
    // if (window.recaptchaVerifier) {
    //   window.recaptchaVerifier.clear();
    // }
    window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
      size: 'invisible',
      callback: (response) => {
        console.log("reCAPTCHA solved", response);
      },
      'expired-callback': () => {
        setError("reCAPTCHA expired. Please try again.");
        toast.error(`reCAPTCHA expired. Please try again.`, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        resetForm();
      },
    });
  };

  const formatPhoneNumber = (phoneNumber) => {
    const cleaned = phoneNumber.replace(/\D/g, '');
    return cleaned.startsWith('974') ? `+${cleaned}` : `+974${cleaned}`;
  };

  const validatePhoneNumber = (phoneNumber) => {
    const phoneRegex = /^\+974[1-9]\d{7}$/;
    return phoneRegex.test(phoneNumber);
  };

  const handlePhoneChange = (e) => {
    const input = e.target.value.replace(/\D/g, '');
    if (input.length <= 8) {
      setPhone(input);
      setError('');
    }
  };

  const resetForm = () => {
    setPhone('');
    setOtp('');
    setShowOTP(false);
    setError('');
    setIsLoading(false);
    setTimeLeft(0);
    setVerificationSuccess(false);
    setupRecaptcha();
  };

  const sendOTP = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    const formattedPhone = formatPhoneNumber(phone);
    
    if (!validatePhoneNumber(formattedPhone)) {
      setError('Please enter a valid 8-digit Qatar mobile number');
      toast.error(`Please enter a valid 8-digit Qatar mobile number`, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      setIsLoading(false);
      return;
    }

    try {
      const appVerifier = window.recaptchaVerifier;
      const confirmation = await signInWithPhoneNumber(auth, formattedPhone, appVerifier);
      setConfirmationResult(confirmation);
      setShowOTP(true);
      setTimeLeft(60); // Start 60 second countdown
    } catch (error) {
      console.error(error);
      setError(getErrorMessage(error));
      toast.error(getErrorMessage(error), {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      setupRecaptcha();
    } finally {
      setIsLoading(false);
    }
  };

  const verifyOTP = async (e) => {
    e.preventDefault();
    setError('');
    setIsLoading(true);

    try {
      await confirmationResult.confirm(otp);
      setVerificationSuccess(true);
      onValidation(phone);
    } catch (error) {
      console.error(error);
      setError('Invalid OTP. Please try again.');
      toast.error('Invalid OTP. Please try again.', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const getErrorMessage = (error) => {
    switch (error.code) {
      case 'auth/invalid-phone-number':
        return 'Invalid phone number format.';
      case 'auth/too-many-requests':
        return 'Too many attempts. Please try again later.';
      case 'auth/quota-exceeded':
        return 'Service temporarily unavailable. Please try again later.';
      default:
        return 'Failed to send OTP. Please try again.';
    }
  };

  const handleOTPChange = (e) => {
    const input = e.target.value.replace(/\D/g, '');
    if (input.length <= 6) {
      setOtp(input);
      setError('');
    }
  };

  

  return (
    <div className="space-y-6 py-10">
      <h2 className="text-xl font-bold text-[#0a2942]">Verify Your Phone</h2>
      <div id="recaptcha-container"></div>
      
    
      
      {!showOTP ? (
        <form onSubmit={sendOTP} className="space-y-4">
          <div className="relative">
            <span className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-500">
              +974
            </span>
            <input
              type="tel"
              value={phone}
              onChange={handlePhoneChange}
              placeholder="Enter 8-digit mobile number"
              className="w-full p-2 pl-16 border rounded-full"
              maxLength="8"
              disabled={isLoading}
            />
          </div>
          <button 
            type="submit"
            className="w-full bg-[#ff8000] hover:bg-[#ff9933] text-white font-bold py-4 max-[450px]:py-2 rounded-full disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center"
            disabled={isLoading || phone.length !== 8}
          >
            {isLoading ? (
              <Loader2 className="w-6 h-6 animate-spin" />
            ) : (
              'Send OTP'
            )}
          </button>
        </form>
      ) : (
        <form onSubmit={verifyOTP} className="space-y-4">
          <input
            type="text"
            value={otp}
            onChange={handleOTPChange}
            placeholder="Enter 6-digit OTP"
            className="w-full p-2 border rounded-full"
            maxLength="6"
            disabled={isLoading}
          />
          {timeLeft > 0 && (
            <p className="text-sm text-gray-500">
              Resend OTP in {timeLeft} seconds
            </p>
          )}
          <div className="space-y-2">
            <button 
              type="submit"
              className="w-full bg-[#ff8000] hover:bg-[#ff9933] text-white font-bold py-4 max-[450px]:py-2 rounded-full disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center"
              disabled={isLoading || otp.length !== 6}
            >
              {isLoading ? (
                <Loader2 className="w-6 h-6 animate-spin" />
              ) : (
                'Verify OTP'
              )}
            </button>
            {timeLeft === 0 && (
              <button
                type="button"
                onClick={resetForm}
                className="w-full border border-[#ff8000] text-[#ff8000] font-bold py-4 max-[450px]:py-2 rounded-full hover:bg-orange-50"
                disabled={isLoading}
              >
                Start Over
              </button>
            )}
          </div>
        </form>
      )}
    </div>
  );
};

export default MobileValidation;