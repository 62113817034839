import config from '../config';


export const getNotificationsByClientId = async (clientId) => {
  const response = await fetch(`${config.apiUrl}/notifications/${clientId}`);
  
  if (!response.ok) throw new Error('Failed to fetch notifications');
  return response.json();
};

export const createNotification = async (notificationData) => {
  const response = await fetch(`${config.apiUrl}/notifications`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(notificationData),
  });

  if (!response.ok) throw new Error('Failed to create notification');
  return response.json();
};

export const deleteNotification = async (notificationId) => {
  const response = await fetch(`${config.apiUrl}/notifications/${notificationId}`, {
    method: 'DELETE',
  });

  if (!response.ok) throw new Error('Failed to delete notification');
};