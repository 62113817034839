// src/config/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getMessaging } from 'firebase/messaging';

const firebaseConfig = {
    apiKey: "AIzaSyCzamSDosuZw19VnkrOGRF4ZrglHWBMmx8",
    authDomain: "gocleans.firebaseapp.com",
    projectId: "gocleans",
    storageBucket: "gocleans.firebasestorage.app",
    messagingSenderId: "195533743916",
    appId: "1:195533743916:web:f3181427aadb9d13ed2e89",
    measurementId: "G-VZW846ZZTW"
  };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const auth=getAuth(app);

export const messaging = getMessaging(app);

export default app;