// src/api/VehicleTypeApi.js
import config from '../config';

export const fetchVehicleTypes = async (filters = {}) => {
  const response = await fetch(`${config.apiUrl}/vehicle-types/`);

  if (!response.ok) throw new Error('Failed to fetch VehicleTypes');
  return response.json();
};
export const createVehicleType = async (VehicleTypeData) => {
  const response = await fetch(`${config.apiUrl}/vehicle-types/`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(VehicleTypeData),
  });
  if (!response.ok) {
    const errorResponse = await response.json(); // Parse the error response
    throw new Error(JSON.stringify(errorResponse)); // Throw the real error message
  }
  return response.json();
};

export const deleteVehicleType = async (VehicleTypeData) => {
  const response = await fetch(`${config.apiUrl}/vehicle-types/${VehicleTypeData.vehicle_type_id}`, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(VehicleTypeData),
  });

  if (!response.ok) {
    const errorResponse = await response.json(); // Parse the error response
    throw new Error(JSON.stringify(errorResponse)); // Throw the real error message
  }
  return response.json();
};

export const editVehicleType = async (VehicleTypeData) => {
  const response = await fetch(`${config.apiUrl}/vehicle-types/${VehicleTypeData.vehicle_type_id}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(VehicleTypeData),
  });

  if (!response.ok) {
    const errorResponse = await response.json(); // Parse the error response
    throw new Error(JSON.stringify(errorResponse)); // Throw the real error message
  }
  return response.json();
};