
import { Bounce, toast } from "react-toastify";
import { editClient } from "../api/clientApi";
import { useAuth } from "../context/AuthContext";
import MobileValidation from "../components/MobileValidation";
import { useNavigate } from "react-router-dom";
const ChangeMobile = () => {
    const { setClientData, clientData } = useAuth();
    const navigate = useNavigate();
    const handleMobileChange = async (event) => {

        try {
            // Attempt to update the profile photo              
            const client = await editClient({ client_id: clientData.client_id, mobile: event });
            console.log(client);
            setClientData(client);
            toast.success(`Mobile update successfully!`, {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
            navigate("/");
        } catch (error) {
            toast.error(` Mobile update Failed! `, {
                position: "top-center",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                transition: Bounce,
            });
        }
    };

    return (
        <div className="min-h-screen bg-[#fdf6ec] flex flex-col items-center justify-start p-0">
            <div className="relative w-[75%] sm:w-[90%] max-[450px]:w-[95%] flex justify-end flex-col items-center  bg-white rounded-t-none rounded-b-3xl  shadow-lg px-4 md:pt-48 sm:pt-28 max-[450px]:pt-20  pt-60 space-y-0">
                <div className="space-y-6 mb-10 sm:mb-6 flex flex-col justify-center items-center">
                    <h1 className="text-3xl max-[450px]:text-lg font-bold text-black"> Change Mobile</h1>
                    <svg className='p-0 m-0 w-24 h-24' viewBox="0 0 160 160" fill="#FF8C00" xmlns="http://www.w3.org/2000/svg">

                        <path d="M51.5312 7.68673C49.125 1.87423 42.7812 -1.21952 36.7188 0.436734L9.21875 7.93673C3.78125 9.43673 0 14.3742 0 19.9992C0 97.3117 62.6875 159.999 140 159.999C145.625 159.999 150.562 156.218 152.062 150.78L159.562 123.28C161.219 117.218 158.125 110.874 152.312 108.468L122.312 95.968C117.219 93.843 111.312 95.3117 107.844 99.593L95.2188 114.999C73.2188 104.593 55.4062 86.7805 45 64.7805L60.4062 52.1867C64.6875 48.6867 66.1562 42.8117 64.0312 37.718L51.5312 7.71798V7.68673Z" fill="#FF8C00" />

                    </svg>

                </div>

                <div className="space-y-6 w-[85%] max-[450px]:w-[95%]  lg:w-[50%] py-10 ">
                    <MobileValidation onValidation={handleMobileChange} />
                </div>
            </div>
            <div className="text-center mt-9">
                <p className="text-[#0a2942]">
                    <a href="/" className="font-bold hover:underline">
                        Back Home.
                    </a>
                </p>
            </div>

        </div>
    )

}
export default ChangeMobile;