import React, { useCallback, useState } from "react";
import { useAuth } from "../context/AuthContext";
import MySubscriptionCard from "../components/Cards/MySubscriptionCard";
import MyCarItem from "../components/Cards/MyCarItem";
import ManageVehicle from "../components/Modal/ManageVehicle";
import {
  AddVehicleToSSubscription,
  deleteClientSubscription,
  RemoveVehicleTSubscription,
} from "../api/subscriptionApi";
import { deleteVehicle, createVehicle, editVehicle } from "../api/vehicleApi";
import { Bounce, toast } from "react-toastify";
import CreateVehicleModal from "../components/Modal/CreateVehicleModal";
import EmptyAdd from "../components/EmptyAdd";

const Go = () => {
  const { clientData, refreshClient } = useAuth();
  const [selectedSubscription, setSelectedSubscription] = useState({});
  const [selectedVehicle, setSelectedVehicle] = useState([]);
  const [editMyVehicle, setEditMyVehicle] = useState(null);
  const [isShowVehDetails, setisShowVehDetails] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleSetVeh = (vehicleData) => {
    setSelectedVehicle(vehicleData); // Set the selected vehicle.
    setisShowVehDetails(true); // Open vehicle details.
  };
  const handleEditVehicle =
    (vehicleData) => {
      setisShowVehDetails(false);
      setEditMyVehicle(() => vehicleData);
      setIsModalOpen(true);
    };
  const handleRemoveVehicle = useCallback(
    async (vehicleData) => {
      console.log("Remove vehicle:", vehicleData);
      if (window.confirm(`Proceed in removing Vehicle ?`) === true) {
        try {
          await deleteVehicle(vehicleData); // Call API to  subscription
          toast.success(`Vehicle Deleted successfully!`, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        } catch (error) {
          toast.error(
            `Error Deleting Vehicle to subscription: ${error.message} `,
            {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              transition: Bounce,
            }
          );
        } finally {
          refreshClient(clientData.client_id);
          setisShowVehDetails(false); // Open vehicle details.
        }
      } else { return; }
    },
    [selectedSubscription]
  );

  const handleRemovefromSub = useCallback(
    async (vehicleData) => {
      console.log(" IA M REMOVE ", vehicleData);
      const payload = {
        client_subscription_id: selectedSubscription.client_subscription_id,
        client_id: clientData.client_id,
        vehicle_id: vehicleData.vehicle_id,
        subscription_id: selectedSubscription.subscription_id,
      };
      if (window.confirm(`Proceed in removing Vehicle from ${selectedSubscription.subscription_name} Package ?`) === true) {
        try {
          await RemoveVehicleTSubscription(payload); // Call API to  subscription
          toast.success(`Vehicle Removed successfully!`, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            transition: Bounce,
          });
        } catch (error) {
          toast.error(
            `Error Removing Vehicle from subscription: ${error.message} `,
            {
              position: "top-center",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              transition: Bounce,
            }
          );
        } finally {
          refreshClient(clientData.client_id);
          setisShowVehDetails(false); // Open vehicle details.
        }

      } else { return }
    },
    [selectedSubscription]
  );

  const handleAddtoSub = async (vehicleData) => {

    if (isVehiclehaveSubscription(vehicleData.vehicle_id)) {
      toast.error(`Vehicle is already subscribed to other Package `, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });

      return;
    }
    const payload = {
      client_subscription_id: selectedSubscription.client_subscription_id,
      client_id: clientData.client_id,
      vehicle_id: selectedVehicle.vehicle_id,
      subscription_id: selectedSubscription.subscription_id,
    };
    if (window.confirm(`Proceed in Adding Vehicle to ${selectedSubscription.subscription_name} Package ?`) === true) {
      try {
        await AddVehicleToSSubscription(payload); // Call API to  subscription
        toast.success(`Vehicle Added successfully!`, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      } catch (error) {
        toast.error(`Error Adding Vehicle to subscription: ${error.message} `, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          transition: Bounce,
        });
      } finally {
        refreshClient(clientData.client_id);
        setisShowVehDetails(false); // Open vehicle details.
      }
    } else { return; }
  };
  const handleCloseCarManager = () => {
    setSelectedVehicle(null);
    setisShowVehDetails(false);
  };
  const handleCloseCreateVehicle = () => {
    setSelectedVehicle(null);
    setIsModalOpen(false);
    setEditMyVehicle(null)
  };
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
  const handleCreateVehicle = async (newVehicleData) => {

    if (window.confirm(`Proceed in ${newVehicleData?.vehicle_id ? 'Editing' : 'Adding New '} Vehicle ?`) === true) {
      try {
        if (newVehicleData?.vehicle_id) {
          await editVehicle(newVehicleData);
        } else {
          await createVehicle(newVehicleData);
        }
      } catch (e) {
        console.log(e);
      } finally {
        refreshClient(clientData.client_id);
        setisShowVehDetails(false); // Open vehicle details.
        setIsModalOpen(false); // Close the modal
        setEditMyVehicle(null)
      }
    } else {
      return;
    }
  };
  const handleUnsubscribe = async (client_subscription_id) => {
    if (window.confirm(`Proceed in Cancelation ?`) === true) {
      try {
        await deleteClientSubscription(client_subscription_id);
      } catch (e) {
        console.log(e);
      } finally {
        refreshClient(clientData.client_id);
        setisShowVehDetails(false); // Open vehicle details.
        setIsModalOpen(false); // Close the modal
        setEditMyVehicle(null)
      }
    } else { return; }
  };
  const isVehicleSubscribed = (vehicleId) => {
    return selectedSubscription?.subscribedVehicles?.some(
      (v) => v.vehicle_id === vehicleId
    );
  };
  const isVehiclehaveSubscription = (vehicleId) => {
    return clientData?.clientSubscriptions?.some((subscription) =>
      subscription.subscribedVehicles?.some((v) => v.vehicle_id === vehicleId)
    );
  };
  return (
    <div className="flex flex-col  justify-start min-h-screen pt-6 ">
      <div className="flex flex-col w-full text-white py-4 ">
        <div className="w-full rounded-t-full bg-[#FF8C00] min-h-[60px] flex  items-center  justify-center gap-3 ">
          <svg
            className="w-8 sm:w-8 "
            viewBox="0 0 54 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M24 0H30C31.6594 0 33 1.34062 33 3V9C33 10.6594 31.6594 12 30 12H24C22.3406 12 21 10.6594 21 9V3C21 1.34062 22.3406 0 24 0ZM6 6H18V10.5C18 12.9844 20.0156 15 22.5 15H31.5C33.9844 15 36 12.9844 36 10.5V6H48C51.3094 6 54 8.69063 54 12V42C54 45.3094 51.3094 48 48 48H6C2.69063 48 0 45.3094 0 42V12C0 8.69063 2.69063 6 6 6ZM16.5 40.9969C16.5 41.55 16.95 42 17.5031 42H36.5062C37.0594 42 37.5094 41.55 37.5094 40.9969C37.5094 38.2312 35.2687 36 32.5125 36H21.5156C18.75 36 16.5187 38.2406 16.5187 40.9969H16.5ZM27 33C28.5913 33 30.1174 32.3679 31.2426 31.2426C32.3679 30.1174 33 28.5913 33 27C33 25.4087 32.3679 23.8826 31.2426 22.7574C30.1174 21.6321 28.5913 21 27 21C25.4087 21 23.8826 21.6321 22.7574 22.7574C21.6321 23.8826 21 25.4087 21 27C21 28.5913 21.6321 30.1174 22.7574 31.2426C23.8826 32.3679 25.4087 33 27 33Z"
              fill="white"
            />
          </svg>
          <h1 className="text-white  font-bold">My Subscriptions</h1>
        </div>
        <div className="w-full  bg-[#FF8C00]  flex flex-col items-center  justify-start gap-3  min-h-[340px] border-t-2 pb-20">
          <div className="w-full max-w-full overflow-x-auto flex flex-nowrap gap-2  p-2 rounded-md justify-around items-center my-4 pb-4">
            {clientData?.clientSubscriptions?.map((subscriptionData) => (
              <button
                key={subscriptionData.subscription_id}
                type="button"
                onClick={() => {
                  setSelectedSubscription(() => subscriptionData);
                }}
                style={{
                  backgroundColor: JSON.parse(
                    subscriptionData?.subscription_features
                  ).color,
                }}
                className={`p-2 max-[450px]:p-1 text-white rounded-3xl items-start shadow-lg  ${selectedSubscription?.client_subscription_id ===
                  subscriptionData.client_subscription_id
                  ? "ring-4 ring-white"
                  : ""
                  }`}
              >
                <MySubscriptionCard
                  subscription={subscriptionData}
                  onDelete={(client_subscription_id) => handleUnsubscribe(client_subscription_id)}
                  showDelete={false}
                />
              </button>
            ))}
          </div>
        </div>
      </div>
      <div className="flex flex-col w-full text-white py-4 -mt-20 ">
        <div className="w-full rounded-t-full bg-[#66B8D9] min-h-[60px] flex  items-center  justify-center gap-3 ">
          <svg
            className="w-8 sm:w-8 "
            viewBox="0 0 48 42"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.675 8.00625L10.2281 15H37.7719L35.325 8.00625C34.9031 6.80625 33.7687 6 32.4938 6H15.5062C14.2312 6 13.0969 6.80625 12.675 8.00625ZM3.7125 15.45L7.0125 6.02813C8.27813 2.41875 11.6812 0 15.5062 0H32.4938C36.3188 0 39.7219 2.41875 40.9875 6.02813L44.2875 15.45C46.4625 16.35 48 18.4969 48 21V34.5V39C48 40.6594 46.6594 42 45 42H42C40.3406 42 39 40.6594 39 39V34.5H9V39C9 40.6594 7.65938 42 6 42H3C1.34062 42 0 40.6594 0 39V34.5V21C0 18.4969 1.5375 16.35 3.7125 15.45ZM12 24C12 23.2044 11.6839 22.4413 11.1213 21.8787C10.5587 21.3161 9.79565 21 9 21C8.20435 21 7.44129 21.3161 6.87868 21.8787C6.31607 22.4413 6 23.2044 6 24C6 24.7956 6.31607 25.5587 6.87868 26.1213C7.44129 26.6839 8.20435 27 9 27C9.79565 27 10.5587 26.6839 11.1213 26.1213C11.6839 25.5587 12 24.7956 12 24ZM39 27C39.7957 27 40.5587 26.6839 41.1213 26.1213C41.6839 25.5587 42 24.7956 42 24C42 23.2044 41.6839 22.4413 41.1213 21.8787C40.5587 21.3161 39.7957 21 39 21C38.2043 21 37.4413 21.3161 36.8787 21.8787C36.3161 22.4413 36 23.2044 36 24C36 24.7956 36.3161 25.5587 36.8787 26.1213C37.4413 26.6839 38.2043 27 39 27Z"
              fill="#FCFBFA"
            />
          </svg>
          <h1 className="text-white  font-bold">My Vehicles</h1>
          <hr></hr>
        </div>
        <div className="w-full  bg-[#66B8D9]  flex flex-col items-center pt-4  justify-start gap-3  min-h-[340px] border-t-2 pb-20">
          <EmptyAdd
            onAddClick={handleOpenModal}
            placeholderText="Add New Car"
            color="text-orange-400"
          />
          {clientData?.vehicle?.map((vehicleData) => (
            <MyCarItem
              key={vehicleData.vehicle_id}
              vehicleData={vehicleData}
              isBelongtoSub={isVehicleSubscribed(vehicleData.vehicle_id)}
              setVehicle={handleSetVeh}
            />
          ))}
        </div>
        <ManageVehicle
          isOpen={isShowVehDetails}
          onClose={handleCloseCarManager}
          onEdit={(vehicleData) => handleEditVehicle(vehicleData)}
          onRemove={(vehicleData) => handleRemoveVehicle(vehicleData)}
          vehicleData={selectedVehicle}
          selectedSubscription={selectedSubscription}
          isBelongtoSub={isVehicleSubscribed(selectedVehicle?.vehicle_id)}
          removeSub={(vehicleData) => handleRemovefromSub(vehicleData)}
          addToSub={(vehicleData) => handleAddtoSub(vehicleData)}
        />

        {isModalOpen && (
          <CreateVehicleModal
            onClose={handleCloseCreateVehicle}
            onSubmit={handleCreateVehicle}
            clientId={clientData.client_id}
            editVehicle={editMyVehicle}
          />
        )}
      </div>
    </div>
  );
};

export default Go;
