// src/utils/firebase-messaging.js
import { getMessaging, onMessage, getToken } from 'firebase/messaging';
import  app from '../config/firebase'; // your existing firebase config

const messaging = getMessaging(app);

// Function to request permission and get token
export const requestNotificationPermission = async () => {
  try {
    const permission = await Notification.requestPermission();
    if (permission === 'granted') {
      const token = await getToken(messaging, {
        vapidKey: 'BIo5tPfAI-Dcqe0tWuGyNMadGwelmwpgMhenDRLPHSusuBzI68rBROdhhfFqScynR9JaYuYRhxLzpzotPf-vTFw'
      });
      return token;
    }
    throw new Error('Permission denied');
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};

// Function to handle foreground messages
export const onForegroundMessage = (callback) => {
  return onMessage(messaging, (payload) => {
    console.log('Received foreground message:', payload);
    
    callback(payload);
  });
};