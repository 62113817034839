import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import MobileValidation from "../components/MobileValidation";
import { editClient,  } from "../api/clientApi";
import { Bounce, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useAuth } from "../context/AuthContext";
import { useNavigate } from "react-router-dom";
import ClientProfilePhoto from "../components/ClientProfilePhoto";

const CompleteProfile = () => {
  const { setClientData, clientData } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const profile = location?.state?.profile? location?.state?.profile : clientData;

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "onBlur", // This triggers validation when inputs lose focus
  });

  const [isMobileValid, setIsMobileValid] = useState(false);
  const [loading, setLoading] = useState(false); // Loading state for form submission
  useEffect(() => {
    // if (!profile)return;
    setValue("username", profile?.username || clientData?.username);
    setValue("email", profile?.email || clientData?.email);
    setValue("mobile", profile?.mobile || clientData?.mobile);
    setIsMobileValid(profile?.is_mobile_validated || clientData?.is_mobile_validated);
    console.log("profile", profile || clientData?.username)
  }, [profile]);
  const handleFormSubmit = async (data) => {
    setLoading(true); // Start loading indicator
    try {
      const { terms, ...cleanData } = data; // Destructure to remove 'terms'
      const processedData = {
        ...cleanData, // Spread the rest of the data
        client_id: profile.client_id,
        is_mobile_validated: isMobileValid,
        is_profile_complete: true,
      };

      const clientData = await editClient(processedData);
      console.log(clientData);
      setClientData(clientData);
      toast.success(`Registered successfully!`, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
      navigate("/");
      reset(); // Reset form fields after success
    } catch (error) {
      toast.error(`Error: ${error.message || "Something went wrong"}`, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        transition: Bounce,
      });
    } finally {
      setLoading(false); // Stop loading indicator
    }
  };


  return (
    <div className="min-h-screen bg-[#fdf6ec] flex flex-col items-center justify-start p-0">
      <div className="relative w-[75%] sm:w-[90%] max-[450px]:w-[95%] flex justify-end flex-col items-center  bg-white rounded-t-none rounded-b-3xl  shadow-lg px-4 md:pt-24 sm:pt-20 max-[450px]:pt-14  pt-28 space-y-0">
        <div className="space-y-4 mb-4 sm:mb-6 justify-between">
          <h1 className="text-xl max-[450px]:text-md font-bold text-black">
            Complete your Profile!
          </h1>
          <div className="items-center justify-center flex"><ClientProfilePhoto /></div>
        </div>

        {isMobileValid ? (
          <>
            <div className="space-y-4 w-[85%] max-[450px]:w-[95%]  lg:w-[50%] py-8 ">
              <h2 className="text-xl font-bold text-[#0a2942]">Sign Up</h2>
              <form id="signup-form" onSubmit={handleSubmit(handleFormSubmit)}>
                {/* Mobile Number Input */}
                <div className="space-y-2 ">
                  <label htmlFor="mobile" className="text-sm font-medium">
                    Mobile Number
                  </label>
                  <input
                    id="mobile"
                    type="tel"
                    {...register("mobile", {
                      required: "Mobile number is required",
                      pattern: {
                        value: /^[0-9]{8}$/,
                        message: "Please enter a valid 10-digit mobile number",
                      },
                    })}
                    className={`w-full px-4 py-3 rounded-full bg-[#f0f9ff] border-none focus:ring-2 focus:ring-[#47b5ff] ${errors.mobile ? "border-red-500" : ""
                      }`}
                      disabled
                  />
                  {errors.mobile && (
                    <p className="text-xs text-red-500">
                      {errors.mobile.message}
                    </p>
                  )}
                </div>

                {/* Name Input */}
                <div className="space-y-2">
                  <label htmlFor="username" className="text-sm font-medium">
                    Username
                  </label>
                  <input
                    id="username"
                    type="text"
                    {...register("username", { required: "Name is required" })}
                    className={`w-full px-4 py-3 rounded-full bg-[#f0f9ff] border-none focus:ring-2 focus:ring-[#47b5ff] ${errors.username ? "border-red-500" : ""
                      }`}
                  />
                  {errors.username && (
                    <p className="text-xs text-red-500">
                      {errors.username.message}
                    </p>
                  )}
                </div>

                {/* Email Input */}
                <div className="space-y-2">
                  <label htmlFor="email" className="text-sm font-medium">
                    Email
                  </label>
                  <input
                    id="email"
                    type="email"
                    {...register("email", {
                      required: "Email is required",
                      pattern: {
                        value:
                          /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
                        message: "Please enter a valid email address",
                      },
                    })}
                    className={`w-full px-4 py-3 rounded-full bg-[#f0f9ff] border-none focus:ring-2 focus:ring-[#47b5ff] ${errors.email ? "border-red-500" : ""
                      }`}
                  />
                  {errors.email && (
                    <p className="text-xs text-red-500">
                      {errors.email.message}
                    </p>
                  )}
                </div>
                <div className="space-y-2">
                  <label htmlFor="password" className="text-sm font-medium">
                    Password
                  </label>
                  <input
                    id="password"
                    type="password"
                    {...register("password", {
                      required: "Parssword is required",
                      pattern: {
                        value:
                          /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()_+=[\]{}|;:'",.<>/?]).{8,10}$/,
                        message:
                          "Password must be 8-10 characters long and include at least one uppercase letter, one lowercase letter, one number, and one special character.",
                      },
                    })}
                    className={`w-full px-4 py-3 rounded-full bg-[#f0f9ff] border-none focus:ring-2 focus:ring-[#47b5ff] ${errors.password ? "border-red-500" : ""
                      }`}
                  />
                  {errors.password && (
                    <p className="text-xs text-red-500">
                      {errors.password.message}
                    </p>
                  )}
                </div>
                <div className="space-y-2 space-x-2">
                  <label htmlFor="terms" className="text-sm font-medium">
                    I agree to the{" "}
                    <a
                      href="https://www.example.com/terms-and-conditions"
                      target="_blank"
                      className="text-[#47b5ff]"
                      rel="noreferrer"
                    >
                      Terms and Conditions
                    </a>
                  </label>
                  <input
                    id="terms"
                    type="checkbox"
                    {...register("terms", {
                      required:
                        "You must agree to the terms and conditions before submitting",
                    })}
                    className={`w-4 h-4 rounded-md bg-[#f0f9ff] border-2 border-[#47b5ff] focus:ring-2 focus:ring-[#47b5ff] ${errors.terms ? "border-red-500" : ""
                      }`}
                  />
                  {errors.terms && (
                    <p className="text-xs text-red-500">
                      {errors.terms.message}
                    </p>
                  )}
                </div>

                <input
                  {...register("status", { required: true })}
                  placeholder="status"
                  type="hidden"
                  className="w-full p-2 border rounded-full mb-3"
                  value={"Active"}
                />
              </form>
            </div>
            <div className="absolute w-[85%] max-[450px]:w-[80%]   lg:w-[50%] bottom-0 translate-y-1/2 ">
              <button
                disabled={isSubmitting || loading}
                onClick={() =>
                  document
                    .getElementById("signup-form")
                    .dispatchEvent(
                      new Event("submit", { cancelable: true, bubbles: true })
                    )
                }
                className="w-full bg-[#ff8000] hover:bg-[#ff9933] text-white font-bold py-4 max-[450px]:py-2  rounded-full transition duration-200"
              >
                {loading ? "Submitting..." : "Update"}
              </button>
            </div>
          </>
        ) : (
          <div>
            <div className="space-y-6 w-full py-10 ">
              <MobileValidation
                mobile={profile?.mobile || ""}
                onValidation={(phone) => {
                  setValue("mobile", phone);
                  setIsMobileValid(true);
                  console.log(phone)
                }}
              />
            </div>
          </div>
        )}
      </div>
      <div className="text-center mt-9">
        <p className="text-[#0a2942] space-x-1">
          Complete Profile Later? 
          <a href="/" className="font-bold hover:underline ">
           {' '} back to GOCLEANS
          </a>
        </p>
      </div>
    </div>
  );
};

export default CompleteProfile;
