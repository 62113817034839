import { GoogleMap, Marker, useLoadScript } from "@react-google-maps/api";
import { useEffect, useState } from "react";
import { fetchBranches } from "../api/branchApi"; // API for fetching branches

const mapContainerStyle = {
  width: "100%",
  height: "100%",
};

const defaultCenter = { lat: 40.7128, lng: -74.006 };

const BranchList = () => {
  const [markerPosition, setMarkerPosition] = useState(defaultCenter);
  const [branches, setBranches] = useState([]);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyCKDtkq_YZF3WZsW44MzRNILMqnKNQ8lus",
  });

  const formatTime = (isoString) => {
    const date = new Date(isoString);
    const hours = String(date.getUTCHours()).padStart(2, "0");
    const minutes = String(date.getUTCMinutes()).padStart(2, "0");
    return `${hours}:${minutes}`;
  };

  useEffect(() => {
    async function getBranches() {
      const data = await fetchBranches({});
      const formattedBranches = data.map((branch) => ({
        ...branch,
        open_hours: formatTime(branch.open_hours),
        close_hours: formatTime(branch.close_hours),
        google_map_url: JSON.parse(branch.google_map_url),
      }));
      setBranches(formattedBranches);
      if (formattedBranches.length > 0) {
        setMarkerPosition(formattedBranches[0].google_map_url); // Set default marker position
      }
    }
    getBranches();
  }, []);

  if (!isLoaded) return <div>Loading...</div>;

  return (
    <div className="flex flex-col md:flex-row w-full rounded-full shadow-lg mt-6">
      {/* Left Panel - Branch Details */}
      <div className="w-full md:w-1/3 p-4 overflow-y-auto bg-blue-100">
        <h2 className="text-xl font-semibold mb-4 text-orange-500">GoCleans Branches</h2>
        {branches.map((branch) => (
          <div
            key={branch.branch_id}
            className="p-4 mb-4 bg-white shadow rounded-lg cursor-pointer hover:bg-gray-50"
            onClick={() => setMarkerPosition(branch.google_map_url)}
          >
            <h3 className="text-lg font-semibold">{branch.name}</h3>
            <p className="text-sm text-gray-600">Location: {branch.location}</p>
            <p className="text-sm text-gray-600">
              Open Hours: {branch.open_hours} - {branch.close_hours}
            </p>
          </div>
        ))}
      </div>

      {/* Right Panel - Map */}
      <div className="w-full md:w-2/3 h-96 ">
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          zoom={12}
          center={markerPosition}
          options={{
            streetViewControl: false,
            mapTypeControl: false,
          }}
        >
          {branches.map((branch) => (
            <Marker
              key={branch.branch_id}
              position={branch.google_map_url}
              title={branch.name}              
            />
          ))}
        </GoogleMap>
      </div>
    </div>
  );
};

export default BranchList;
