// src/api/CouponApi.js
import config from '../config';

export const fetchCoupons = async (filters = {}) => {
  const response = await fetch(`${config.apiUrl}/coupons/`);

  if (!response.ok) throw new Error('Failed to fetch Coupons');
  return response.json();
};
export const createCoupon = async (CouponData) => {
  const response = await fetch(`${config.apiUrl}/coupons/`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(CouponData),
  });
  if (!response.ok) {
    const errorResponse = await response.json(); // Parse the error response
    throw new Error(JSON.stringify(errorResponse)); // Throw the real error message
  }
  return response.json();
};

export const deleteCoupon = async (CouponData) => {
  const response = await fetch(`${config.apiUrl}/coupons/${CouponData.coupon_id}`, {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(CouponData),
  });

  if (!response.ok) {
    const errorResponse = await response.json(); // Parse the error response
    throw new Error(JSON.stringify(errorResponse)); // Throw the real error message
  }
  return response.json();
};

export const editCoupon = async (CouponData) => {
  const response = await fetch(`${config.apiUrl}/coupons/${CouponData.coupon_id}`, {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(CouponData),
  });

  if (!response.ok) {
    const errorResponse = await response.json(); // Parse the error response
    throw new Error(JSON.stringify(errorResponse)); // Throw the real error message
  }
  return response.json();
};
export const searchCoupon = async (filters={}) => {
  const query = new URLSearchParams(filters).toString();
  const response = await fetch(`${config.apiUrl}/coupons/search/param=?&${filters}`);
  if (!response.ok) {
    const errorResponse = await response.json(); // Parse the error response
    throw new Error(JSON.stringify(errorResponse)); // Throw the real error message
  }
  return response.json();
};