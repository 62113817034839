// config.js
const isDevelopment = process.env.NODE_ENV === 'development';

const config = {
  apiUrl: isDevelopment ? 'http://localhost:4002' : 'https://api.gocleans.co',
  isDev:isDevelopment,

};

export default config;
